import {createContext, useContext, useState} from 'react';
import {ArtistaBoard} from '~/lib/artista-board/artista-board';
import {IArtistaBoardState} from '~/lib/artista-board/types/artista-board.response-types';
import {IArtistaBoardSettings} from '../../lib/artista-board/types/artista-board.response-types';

const ArtistaBoardContext = createContext(undefined);

function ArtistaBoardProvider({children}) {
  const [artistaBoard, setArtistaBoard] = useState<ArtistaBoard>();
  const [artistaBoardState, setArtistaBoardState] =
    useState<IArtistaBoardState>({} as IArtistaBoardState);
  const [artistaBoardSettings, setArtistaBoardSettings] =
    useState<IArtistaBoardState>({} as IArtistaBoardState);
  const [error, setError] = useState<string>();

  const value: {
    artistaBoard: ArtistaBoard;
    setArtistaBoard: React.Dispatch<ArtistaBoard>;
    artistaBoardState: IArtistaBoardState;
    setArtistaBoardState: React.Dispatch<IArtistaBoardState>;
    artistaBoardSettings: IArtistaBoardSettings;
    setArtistaBoardSettings: React.Dispatch<IArtistaBoardSettings>;
    error?: string;
    setError: React.Dispatch<string>;
  } = {
    artistaBoard,
    setArtistaBoard,
    artistaBoardState,
    setArtistaBoardState,
    artistaBoardSettings,
    setArtistaBoardSettings,
    error,
    setError,
  };

  return (
    <ArtistaBoardContext.Provider value={value}>
      {children}
    </ArtistaBoardContext.Provider>
  );
}

function useArtistaBoard() {
  const context = useContext(ArtistaBoardContext);
  if (context === undefined) {
    throw new Error(
      'useArtistaBoard must be used within a ArtistaBoardContext',
    );
  }
  return context;
}

export {ArtistaBoardProvider, useArtistaBoard};
